import * as React from "react"
import{ graphql }from "gatsby"
import "mapbox-gl/dist/mapbox-gl.css"
import Map from '../components/Map.js'
// import { GatsbyImage, getImage } from "gatsby-plugin-image"


import Layout from "../components/layout"
import Seo from "../components/seo"
import HomeIntro from "../components/HomeIntro.js"
import HomeDataBlocks from "../components/HomeDataBlocks.js"

export const query = graphql`
    query forestryQuery {
      articles: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/posts/"}}) {
        nodes {
          frontmatter {
            coordinates {
              latitude
              longitude
            }
            title
            post_type
            content_to_show
            date_of_test
            country
            responsible_nuclear_state
            button_label
            button_url
            featured_image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            } 
          }
          html
        }
      },
      regions: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/regions/"}}) {
      nodes {
        frontmatter {
          title
          subtitle
          activism_for_justice
          atmospheric_tests
          testimonials
          underground_tests
          button_label
          button_url
          coordinates {
            latitude
            longitude
          }
          machine_name
        }
        html
      }
    }
   intro: markdownRemark(fileAbsolutePath: {regex: "/intro/"}) {
      frontmatter {
        headline
        subheadline
        light_or_dark_text_selector
        homepage_hero_header_bg_image {
          childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      }
      html
  }
  blocks: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/home-data-blocks/"}}
    sort: {fields: frontmatter___order, order: ASC}
    ) {
    nodes {
      fileAbsolutePath
      frontmatter {
        headline
        block_image {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH,
              placeholder: BLURRED
              )
          }
        }
        button_label
        button_url
      }
      html
    }
  }
  narrativeHeader: markdownRemark(fileAbsolutePath: {regex: "/data-blocks-heading/"}) {
    frontmatter {
      heading
    }
  }
}
`;


const IndexPage = ({data}) =>  {
 const narrativeHeading = data.narrativeHeader.frontmatter.heading; 
  return (
    <Layout>
      <Seo title="Home" />
      <HomeIntro data={data} />
      <Map data={data} />
      <div className="homeDataBlocks">
        <h2>{narrativeHeading}</h2>
        <HomeDataBlocks data={data} />
      </div>
    </Layout>
  )
}

export default IndexPage

import React from 'react';
import atmosIcon from '../map-imgs/Atmos4x.png';
import underIcon from '../map-imgs/Underground4x.png';
import storyIcon from '../map-imgs/Story4x.png';
import actIcon from '../map-imgs/Justice4x.png';

const RegionCard = ({ openedReg, region, handleRegionClose }) => {
    const linkBtn = (
        <a href={region.frontmatter.button_url} rel="noreferrer" target="_blank" className="btn btn-primary">{region.frontmatter.button_label}</a>
    )

    const atmosToggle = region.frontmatter.atmospheric_tests;
    const undergroundToggle = region.frontmatter.underground_tests;
    const actToggle = region.frontmatter.activism_for_justice;
    const storyToggle = region.frontmatter.testimonials;

    let postsInRegion = [ 
        {
            name: 'atmos',
            value: atmosToggle,
            img: atmosIcon 
        },
        {
            name: 'underground',
            value: undergroundToggle,
            img: underIcon
        },
        {
            name: 'activism',
            value: actToggle,
            img: actIcon
        },
        {
            name: 'story',
            value: storyToggle,
            img: storyIcon
        }
    ];
    let filteredRegionPosts = postsInRegion.filter((regionPost) => regionPost.value === true)
    const finalRegionPosts = filteredRegionPosts.map((regionPost) => {
        return (
            <li>
            <img src={regionPost.img} />
            </li>
        )
    })


    const inThisRegion = (
        <div class="regionPostsKey">
        <p>In this region: </p>    
            <ul>
                {finalRegionPosts}
            </ul>
        </div>
    )
    const slug = region.frontmatter.machine_name;
    return (
        <div className={` ${ openedReg ? 'showOpen' : '' } regionCard ${slug}`}>
            <span className="regionClose" onClick={() => handleRegionClose()}>X</span>
            <h2>{region.frontmatter.title}</h2>
            {inThisRegion}
            <div className="regionContent" dangerouslySetInnerHTML={{__html: region.html }}>
            </div>
            {region.frontmatter.button_label !== "" ? linkBtn : null }
        </div>
    )
  }
  
  export default RegionCard
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import atmosIco from "../images/atmosIcon.svg"
import undergroundIco from "../map-imgs/Underground4x.png"
import justiceIco from "../images/justiceIcon.svg"
import storyIco from "../map-imgs/Story4x.png"
//Country Images
import Nk from "../map-imgs/country-nk.svg"
import China from "../map-imgs/Country-China.svg"
import France from "../map-imgs/Country-France.svg"
import India from "../map-imgs/Country-India.svg"
import Pakistan from "../map-imgs/Country-Pakistan.svg"
import US from "../map-imgs/Country-usa.svg"
import UK from "../map-imgs/country-uk.png"
import Israel from "../map-imgs/Country-Israel.svg"
import USSR from "../map-imgs/Country-ussr.svg"

const BombPost = ({ opened = false, post, handlePostClose }) => {
  const image = getImage(post?.frontmatter?.featured_image)
  const testData = (
    <ul>
      <li>Date: {post.frontmatter.date_of_test}</li>
      <li>Country: {post.frontmatter.country}</li>
    </ul>
  )

  const state = post.frontmatter.responsible_nuclear_state
  const respStateImg =
    state === "Israel" ? (
      <img src={Israel} alt="israel flag" />
    ) : state === "USA" ? (
      <img src={US} alt="American flag" />
    ) : state === "UK" ? (
      <img src={UK} alt="UK flag" />
    ) : state === "Pakistan" ? (
      <img src={Pakistan} alt="Pakistan flag" />
    ) : state === "India" ? (
      <img src={India} alt="India flag" />
    ) : state === "North Korea" ? (
      <img src={Nk} alt="North korea flag" />
    ) : state === "China" ? (
      <img src={China} alt="China flag" />
    ) : state === "USSR" ? (
      <img src={USSR} alt="USSR flag" />
    ) : state === "France" ? (
      <img src={France} alt="France flag" />
    ) : null
  const respState = (
    <div className="respStateDiv">
      {respStateImg}
      <h5 className="respState">
        Responsible Nuclear State: {post.frontmatter.responsible_nuclear_state}
      </h5>
    </div>
  )

  const linkBtn = (
    <div className="btn-wrapper">
      <a
        href={post.frontmatter.button_url}
        rel="noreferrer"
        target="_blank"
        className="btn btn-primary"
      >
        {post.frontmatter.button_label}
      </a>
    </div>
  )

  const typeClass = post.frontmatter.post_type

  const typeImg =
    typeClass === "Atmospheric" ? (
      <img src={atmosIco} alt="Atmospheric test" />
    ) : typeClass === "Underground" ? (
      <img src={undergroundIco} alt="Underground Test" />
    ) : typeClass === "Activism" ? (
      <img src={justiceIco} alt="Activism for Justice" />
    ) : typeClass === "Testimonial" ? (
      <img src={storyIco} alt="Testimonial" />
    ) : null

  return (
    <div className={` ${opened ? "showOpen" : ""} bombPostCard ${typeClass}`}>
      <span className="postClose" onClick={() => handlePostClose()}>
        X
      </span>
      <div className="bombPostHeader">
        {typeImg}
        <h2>{post.frontmatter.title}</h2>
      </div>
      {post.frontmatter.content_to_show === "Underground/Atmospheric"
        ? testData
        : null}
      {post.frontmatter.content_to_show === "Underground/Atmospheric"
        ? respState
        : null}
      {post.frontmatter.featured_image && (
        <div className="image-wrapper-new">
          <GatsbyImage image={image} alt={post.frontmatter.title} />
          <a
            href="/faq"
            className="overlay-link"
            target="_blank"
            rel="noreferrer"
          ></a>
        </div>
      )}
      <div
        className="articleContent"
        dangerouslySetInnerHTML={{ __html: post.html }}
      ></div>
      {post.frontmatter.button_label !== "" ? linkBtn : null}
      <span className="postCloseText" onClick={() => handlePostClose()}>
        Click here to close
      </span>
    </div>
  )
}

export default BombPost

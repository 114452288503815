import React, { useRef, useEffect, useState } from "react"
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import 'bootstrap/dist/css/bootstrap.min.css';
//Components
import BombPost from "./BombPost";
import Sidebar from './Sidebar';
import MapKey from "./MapKey";
import RegionCard from "./RegionCard";
//Images
import regionBg from '../map-imgs/region-blurred.png'
import underground from '../map-imgs/Underground4x.png'
import atmospheric from '../map-imgs/Atmos4x.png'
import story from '../map-imgs/Story4x.png'
import activism from '../map-imgs/Justice4x.png'




const mapContainerStyle = {
  width: "100%",
}


const Map = ({data}) => {
 //Map setup
  const mapContainerRef = useRef(null)
  const [map, setMap] = useState(null)
  //Forestry Map Data  
  const posts = data.articles.nodes;
  const regionDats = data.regions.nodes;
   //States 
  const [clickedPost, setClickedPost] = useState('')
  const [opened, setOpened] = useState(false)
  const [openedReg, setOpenedReg] = useState(false)
  const [restricted, setRestricted] = useState(false)
  const [regionBar, setRegion] = useState('')
  const [dropdown, setDropdown] = useState(false)
  const [regionSelect, setRegionSelect] = useState('')
  //Event handlers
    const clickPoint = (clicked) => {
    const filteredPost = posts.filter( post => post.frontmatter.title === clicked);
    setClickedPost(filteredPost[0]);
    setOpened(true)
    setOpenedReg(false)
  }
//Event handler for region blob click on map
  const clickRegionBlob = (clickedRegionBlob) => {
    const filteredClick = regionDats.filter( region => region.frontmatter.title === clickedRegionBlob);
    setRegionSelect(filteredClick[0]);
    setOpenedReg(true)
  }
//Event handler for region dropdown click
  const clickRegion = (clickedo) => {
    const filteredRegion = regionDats.filter(region => region.frontmatter.title === clickedo);
    setRegion(filteredRegion[0].frontmatter.title);
  }



//Region Points JSON Collection
const regionPoints = regionDats.map((region => {
    return (
        {
            "type": "Feature",
            "properties": {
            "subtitle": region.frontmatter.subtitle,
            "name": region.frontmatter.title
            },
            "geometry": 
            {
                "type": "Point",
                "coordinates": [
                region.frontmatter.coordinates.longitude,
                region.frontmatter.coordinates.latitude
                ]
            }
        } 
    )
}))

const regionCollection =  {
    "type": "FeatureCollection",
    "features": regionPoints
  }
  
//Post Point JSON Collections
    const customFeatures = posts.map((post) => {
    return (
            {
            "type": "Feature",
            "properties": {
            "testType": post.frontmatter.post_type,
            "name": post.frontmatter.title
            },
            "geometry": 
            {
                "type": "Point",
                "coordinates": [
                post?.frontmatter?.coordinates?.longitude,
                post?.frontmatter?.coordinates?.latitude
                ]
            }
        }
    )
})
const postCollection =  {
  "type": "FeatureCollection",
  "features": customFeatures
}

const MAPBOX_TOKEN = 'pk.eyJ1IjoiaWNhbi12YS1lci1tYXAiLCJhIjoiY2t6Y2pjZ2ZxMXpsaDJvbnhmMnpuc3k1aiJ9.mnFrTyNE7R8zytgkIFRUJQ';

//Map initilization here
  useEffect(() => {
    const map = new mapboxgl.Map({
    container: mapContainerRef.current,
    accessToken: MAPBOX_TOKEN,
      style: "mapbox://styles/ican-va-er-map/ckw0ukhng3ii514mpwenij9a1",
      center: [7.7344, 32.1756],
      maxZoom: 8.85,
      minZoom: 1.99,
      zoom: 1.2
    })
    const navigation = new mapboxgl.NavigationControl();
    map.addControl(navigation, 'bottom-right')


        map.on('load', () => {
            // This function loads the region blobs and places them on the map after the Map canvas is loaded
            map.loadImage(
                regionBg,
                (error, image) => {
                    if (error) throw error;
            
                    map.addImage(regionBg, image, {sdf: true})
                    map.addSource('region', {
                        type: 'geojson',
                        data: regionCollection
                    });
                    map.addLayer({
                        id: 'region',
                        type: 'symbol',
                        source: 'region',
                        minzoom: 0,
                        maxzoom: 2.45,
                        layout: {
                            'icon-image': regionBg,
                            'icon-size': ['interpolate', ['linear'], ['zoom'], 0, 0.75, 2, 0.77],
                            'icon-allow-overlap': true,
                            'text-field': ["format",
                            ['get', 'name'], {"font-scale": 1.2},
                            "\n", {},
                            ['get', 'subtitle'], {"font-scale": 0.8}
                        ],
                            'text-anchor': 'center',
                            'text-justify': 'auto',
                            'text-font': ['Montserrat Bold'],
                            'text-ignore-placement': true,
                            'text-padding': 4
                        },
                        paint: {
                            'icon-opacity': 0.62,
                            'icon-color': '#fff'
                            // 'icon-halo-blur': .3,
                            // 'icon-halo-width': 1,
                            // 'icon-halo-color': '#fff',
                            // 'text-color': ['get, subtitle'], 'rgba(138, 21, 56, 1)'
                        }
                    })
                }
            );

                
        
            //These are the images used for the featured points on the map
                const imgs = [
                    underground,
                    atmospheric,
                    story,
                    activism
                ]
                // This is where the featured points are added to the map
                Promise.all(
                    imgs.map(img => new Promise((resolve, reject) => {
                        map.loadImage(img, function (error, res) {
                            map.addImage(img, res)
                            resolve();
                        })
                    }))
                )
                .then(
                    map.addSource('tests', {
                        type: 'geojson',
                        data: postCollection
                    }),
                    map.addLayer({
                        id: 'feat-tests',
                        type: 'symbol',
                        source: 'tests',
                        minzoom: 2.45,
                        maxzoom: 10,
                        layout: {
                            'icon-image': [
                                                        'match',
                                                        ['get', 'testType'],
                                                        'Underground',
                                                        underground,
                                                        'Atmospheric',
                                                        atmospheric,
                                                        'Testimonial',
                                                        story,
                                                        'Activism',
                                                        activism,
                                                        ""
                                                        ],
                            'icon-size': ['interpolate', ['linear'], ['zoom'], 0, 0.20,1.5, 0.25, 2, 0.25, 7.5, 0.80,  8, 1, 10, 1.2],
                            'icon-allow-overlap': true,
                            'symbol-sort-key': 2
                        },
                        paint: {
                            'icon-halo-color': 'rgba(255, 206, 81, 1)',
                            'icon-halo-width':2,
                            'icon-halo-blur': 4
                        }
                    })
                )

                // Added circles to give illusion of halo on featured map point click (min visibility at certain zoom level).
                map.addSource('circle-tests', {
                    type: 'geojson',
                    data: postCollection
                })
                map.addLayer({
                    id: 'circleos',
                    type: 'circle',
                    source: 'circle-tests',
                    minzoom: 7.95,
                    maxzoom: 8,
                    paint: {
                        'circle-color': 'rgba(255, 206, 81, 1)',
                        'circle-blur':0.5,
                        'circle-opacity': 0,
                        'circle-radius': 90,
                        'circle-stroke-opacity': 1,
                        'circle-stroke-width': 12,
                        'circle-stroke-color': 'rgba(255, 206, 81, 1)'
                    },
                    layout: {
                        'circle-sort-key': 1
                    }
                })

                       
            // Below this is closure of onload function
            });


                // Helper Map Functions - changing cursor on hover of clickable ponts
                    map.on('mouseenter', 'feat-tests', () => {
                        map.getCanvas().style.cursor = 'pointer';
                        });
                    map.on('mouseleave', 'feat-tests', () => {
                    map.getCanvas().style.cursor = '';
                    });

                    map.on('mouseenter', 'region', () => {
                        map.getCanvas().style.cursor = 'pointer';
                        });
                        
                        map.on('mouseleave', 'region', () => {
                        map.getCanvas().style.cursor = '';
                        });

                    //Blog post click helper functions

                    const restrictMap = () => {
                        map.boxZoom.disable();
                        map.scrollZoom.disable();
                        map.dragPan.disable();
                        map.dragRotate.disable();
                        map.keyboard.disable();
                        map.doubleClickZoom.disable();
                        map.touchZoomRotate.disable();
                        setRestricted(true);
                    }

                    // Featured point click
                    map.on('click', 'feat-tests', (e) => {
                            const clicked = e.features[0].properties.name
                            map.flyTo({
                                center: e.features[0].geometry.coordinates,
                                zoom: 7.95,
                                speed: 0.85,
                                pitch: 65,
                                bearing: 10
                            });
                            clickPoint(clicked)
                            restrictMap()
                    })

                    //Region click
                    map.on('click', 'region', (e) => {
                        const clickedRegionBlob = e.features[0].properties.name
                        map.flyTo({
                            center: e.features[0].geometry.coordinates,
                            zoom: 2.45,
                            speed: 1
                        });
                        clickRegionBlob(clickedRegionBlob)
                        restrictMap()
                    })



    setMap(map)

    return () => map.remove()
  }, [])


  // Various helper functions to enable or restrict map depending on if a post is open or not
  const enableMap = () => {
    map.boxZoom.enable();
    map.scrollZoom.enable();
    map.dragPan.enable();
    map.dragRotate.enable();
    map.keyboard.enable();
    map.doubleClickZoom.enable();
    map.touchZoomRotate.enable();
    setRestricted(false);
    map.flyTo({
        zoom: 4,
        pitch: 0,
        bearing: 0
    })
}

const enableRegionMap = () => {
    map.boxZoom.enable();
    map.scrollZoom.enable();
    map.dragPan.enable();
    map.dragRotate.enable();
    map.keyboard.enable();
    map.doubleClickZoom.enable();
    map.touchZoomRotate.enable();
    setRestricted(false);
}

  const handlePostClose = () => {
    setOpened(false)
    enableMap()
    setClickedPost("")
}

const handleRegionClose = () => {
    setOpenedReg(false)
    enableRegionMap()
    setRegionSelect('')
}

const zoomOut = () => {
    map.zoomOut({offset: [80, 60]});
}

const flyToRegion = (e) => {
    const clickedo = e.target.id;
    clickRegion(clickedo)
    const clickedLat = e.target.getAttribute('data-latitude');
    const clickedLong = e.target.getAttribute('data-longitude');
    const clickedCoords = [clickedLong, clickedLat];
    setDropdown(false)
    map.flyTo({
        center: clickedCoords,
        zoom: 2.44,
    });
}

  return (
      
              <div restricted={restricted} className={opened ? "opened mapWrapper" : openedReg ? "openedReg mapWrapper" : "mapWrapper"} id="Map">
                  <div className="mobWrapper">
                    <Sidebar dropdown={dropdown} setDropdown={setDropdown} regions={regionDats} regionBar={regionBar} opened={opened} openedReg={openedReg} zoomOut={zoomOut} flyToRegion={flyToRegion}/>
                    <MapKey post={clickedPost}/>
                  </div>
                    <div ref={mapContainerRef} style={mapContainerStyle}></div>
                    { clickedPost ? <BombPost handlePostClose={handlePostClose} opened={opened} post={clickedPost}/> : null } 
                    {regionSelect ? <RegionCard handleRegionClose={handleRegionClose} openedReg={openedReg} region={regionSelect} /> : null}
              </div>

  )

}


export default Map
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function HomeDataBlocks({ data }) {

  const blocks = data.blocks.nodes

  return blocks.map(block => (
    <div className="data-card" key={block.frontmatter.headline}>
      <div class="data-card--image">
        <GatsbyImage
          image={getImage(block.frontmatter.block_image)}
          alt={block.frontmatter.headline}
        />
        <h3>{block.frontmatter.headline}</h3>
      </div>
      <div
        className="blockSummary"
        dangerouslySetInnerHTML={{ __html: block.html }}
      ></div>
      <a
        href={block.frontmatter.button_url}
        rel="noreferrer"
        target="_blank"
        className="btn btn-primary"
      >
        {block.frontmatter.button_label}
      </a>
    </div>
  ))
}

import React from 'react';
import atmosIcon from '../map-imgs/Atmos4x.png';
import undergroundIcon from '../map-imgs/Underground4x.png';
import storyIcon from '../map-imgs/Story4x.png';
import justiceIcon from '../map-imgs/Justice4x.png';

export default function MapKey ({ post }) {
const atmosCheck = (
    post !== "" && post.frontmatter.post_type === "Atmospheric" ? 
    "active" 
    : null
)
const ugCheck = (
    post !== "" && post.frontmatter.post_type === "Underground" ? 
    "active" 
    : null
)
const storyCheck = (
    post !== "" && post.frontmatter.post_type === "Testimonial" ? 
    "active" 
    : null
)
const jusCheck = (
    post !== "" && post.frontmatter.post_type === "Activism" ? 
    "active" 
    : null
)


    return (
        <div className="keyBar">
            <ul>
                <li className={atmosCheck}>
                    <img src={atmosIcon} alt="atmospheric tests"/>
                    <small>Atmospheric Test</small>
                </li>
                <li className={ugCheck}>
                    <img src={undergroundIcon} alt="underground tests"/>
                    <small>Underground Test</small>
                </li>
                <li className={storyCheck}>
                    <img src={storyIcon} alt="testimonial"/>
                    <small>Stories and Testimonials</small>
                </li>
                <li className={jusCheck}>
                    <img src={justiceIcon} alt="activism for justice"/>
                    <small>Activism for Justice</small>
                </li>
            </ul>
        </div>
    )
}


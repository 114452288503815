import React from 'react';
import targetImg from '../images/iconTarget.svg'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';


export default function HomeIntro ({data}) {
    const intro = data.intro;
    const imageData = getImage(data.intro.frontmatter.homepage_hero_header_bg_image);
    const textColor = data.intro.frontmatter.light_or_dark_text_selector;
    return (
        <div className="introSection">
            <div class="gradient-overlay" />
            <GatsbyImage image={imageData} alt={intro.frontmatter.headline} className="heroBG"/>
            <img src={targetImg} alt="target icon" className="mobileTargetImg"/>
            <div className={`${ textColor === 'Light' ? 'lightText' : 'darkText' } introText`}>
            <h1 className="headline">{intro.frontmatter.headline}</h1>
            <h1 className="subheadline">{intro.frontmatter.subheadline}</h1>
            <div className="introSummary" dangerouslySetInnerHTML={{__html: intro.html }} />
            <h5><Link to="#Map">Explore the Map</Link></h5>
            </div>
        </div>
    )
}
import React from "react";
import worldMap from '../images/dropdownWorldMap.svg';



export default function Sidebar ({regions, flyToRegion, zoomOut, regionBar, dropdown, setDropdown, opened, openedReg}) {

    const toggleDropdown = () => {
        setDropdown(!dropdown)
        }      
    return (
             <div className={` ${ opened ? 'd-none ' : openedReg ? 'd-none ' : ''}dropdown regionDropdown`}>
                 <img src={worldMap} alt="world map dropdownbutton" onClick={() => zoomOut()}/>
                <button className="btn dropdown-toggle" type="button" id="regionDropdownBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded={dropdown ? "true" : "false"} onClick={()=> toggleDropdown()}>
                    {regionBar ? `${regionBar}` : "Select Region"}
                </button>
                <div className={dropdown ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="regionDropdownBtn">
                    {regions.map(region => 
                    <p onClick={(e) => flyToRegion(e)} key={region.frontmatter.title} data-latitude={region.frontmatter.coordinates.latitude} data-longitude={region.frontmatter.coordinates.longitude} id={region.frontmatter.title}>{region.frontmatter.title}</p>
                    )}
                </div>
            </div> 
    )
}